/* CSS */

/* Global Jumbotrons, Heroes and Carousels. */
/* (Some of the same classes may appear on local pages. They may be being used differently. ) */

/*Photo Pullquote with hero class "inline" _____________________________________*/

/*inline path*/
.hero-container.inline .jumbotron div.jumboChild p.hero-headline {
}

.hero-container.inline .jumbotron .jumboChild {
	height: 100%;
}

.hero-container.inline .jumbotron .jumboChild p {
	color: #fff;
	font-family: Nunito, Arial, Helvetica, sans-serif;
	font-size: 24px;
	line-height: 2.3;
	position: relative;
	z-index: 9;
	margin-bottom: 0;
}

/*resize text @ different sizes*/
.hero-container.inline .jumbotron .jumboChild p {
	text-align: center;
	font-size: 24px !important;
	margin: 0 auto 40px;
	width: 90%;
}

@media (max-width: 1199px) {
	.hero-container.inline .jumbotron .jumboChild p {
		font-size: 24px !important;
		line-height: 2.3;
		width: 70%;
	} 
}

@media (max-width: 991px) {
	.hero-container.inline .jumbotron .jumboChild p {
		font-size: 24px !important;
		line-height: 2.2;
		width: 70%;
	} 
}

@media (max-width: 767px) {
	.hero-container.inline .jumbotron .jumboChild p {
		font-size: 21px !important;
		line-height: 2.3;
		width: 80%;
	} 
}

@media (max-width: 479px) {
	.hero-container.inline .jumbotron .jumboChild p {
		font-size: 18px !important;
		line-height: 1.8;
		width: 80%;
	} 
}

/* END Photo Pullquote "inline" */

/* _________________ Page Carousels _________________ */

.pageCarousel {
	max-width: 765px;
}

.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
	width: 100%;	/*was 70%. might need to match hero widths. make images same dimensions. */
	margin: auto;
}

/* Home Carousel */		/*<-- RENAME paths */ /**/
#header-carousel div.carousel-inner {
	width: auto;
	margin: 0 auto;
	}

/*#header-carousel .item  {
	height: 500px;
}*/

.carousel-control:hover {
	cursor: pointer;
}

/* No gradients on controls. */
.carousel-control.left {
	background-image: none;
	background-repeat: repeat-x;
	left: 0;
	right: auto;
	height: 100%;
	width: 80px;
	z-index: 999;
}

.carousel-control.right {
	background-image: none;
	background-repeat: repeat-x;
	left: auto;
	right: 0;
	height: 100%;
	width: 80px;
	z-index: 999;
}

.carousel-control .glyphicon-chevron-left, 
.carousel-control .glyphicon-chevron-right {
	display: none;
	width: 42px;
	height: 42px;
	margin: 0 -2px 0 -2px;
	padding: 0;
	opacity: .55;
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right  {
	font-size: 42px;
}

.carousel-control .fa-angle-left,
.carousel-control .fa-angle-right {
	display: block;
	font-size: 5em;
	color: #FFFFFF;
}

@media (max-width: 767px) {
	.carousel-control .fa-angle-left,
	.carousel-control .fa-angle-right {
		font-size: 3em;
	}
}

@media (max-width: 479px) {
	.carousel-control .fa-angle-left,
	.carousel-control .fa-angle-right {
		font-size: 2.5em;
	}
}

/*@media (max-width: 319px) {
	.carousel-control .fa-angle-left,
	.carousel-control .fa-angle-right {
		font-size: 2em;
	}
}
*/
.carousel-control .fa-angle-left {
	left: 20px !important;
	right: initial !important;
}


.carousel-control .fa-angle-right {
	right: 20px !important;
	left: initial !important;
}

@media (max-width: 479px) {
	.carousel-control .fa-angle-left {
		left: 5px !important;
		right: initial !important;
	}


	.carousel-control .fa-angle-right {
		right: 5px !important;
		left: initial !important;
	}
}


.carousel-indicators li {
	margin-right: 8px;
	/*background-color: #fff;*/
	opacity: .4;
	/*border: solid 1px transparent;*/
    border: solid 1px #fff;
}

.carousel-indicators .active, .carousel-indicators li:hover {
	margin-right: 8px;
    opacity: 0.75;
    /*background-color: #00A94F;*/
    background-color: #fff;
}

/* Set max height */
.carousel, .carousel-inner {
}

/* Carousel overlay */
.carouselText {
	position: absolute; 
	/*bottom: -70px; */
	right: 0; 
	left: 0; 
	text-align: center;
	z-index: 10;
	padding: 0 100px;
	background: transparent;
	text-align: center;
	z-index: 9;
	display: block;	
	padding: 0 80px;
}

@media (max-width: 767px) {
	.carouselText {
		padding: 0 50px;
	}
}

@media (max-width: 479px) {
	.carouselText {
		padding: 0 30px;
	}
}

@media (max-width: 479px) {
	.carouselText {
		padding: 0 20px;
	}
}

.carouselText .slideNumber {
	margin: 0;
}

.pageCarousel .overlayContainer .vcenter h2 {
	background-color: transparent;
	padding: 0;
	text-align: center;
	font-size: 22px;
}

@media (max-width: 767px) {
	.pageCarousel .overlayContainer .vcenter h2 {
		font-size: 19px;
	}
}

@media (max-width: 479px) {
	.pageCarousel .overlayContainer .vcenter h2 {
		font-size: 16px;
	}
}

@media (max-width: 319px) {
	.pageCarousel .overlayContainer .vcenter h2 {
		font-size: 15px;
	}
}

.carouselOverlay {
	background: rgba(0,0,0,0.3);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
}


/* When Heros and carousels are links - hide underlines. */
a.heroesCarousels:hover {
	text-decoration: none;
}

/* when carousels are in-page - class: ".pageCarousel" */

.pageCarousel .carousel-inner {
}

.pageCarousel .jumbotron .container {
	width: 90% !important;
}

/*.pageCarousel ol.carousel-indicators {
	bottom: -36px;
    left: 0;
    text-align: right;
    width: 100%;
}

.pageCarousel ol.carousel-indicators:last-child {
	margin: 0 !important;
	height: 24px;
}

.pageCarousel ol.carousel-indicators li {
	background: #aba39e;
	border-radius: 0;
	display: inline-table;
	height: 20px;
	width: 20px;
}*/

.pageCarousel ol.carousel-indicators {
	margin-bottom: 0;
	display: block;
}

@media (max-width: 479px) {
	.pageCarousel ol.carousel-indicators {
		bottom: 0;
	}
}

.pageCarousel ol.carousel-indicators li {
	margin: 1px;
	width: 10px;
	height: 10px;
}

.pageCarousel .vcenter {
	z-index: 20;
	left: 0;
	right: 0;
	display: block;
}

.pageCarousel .vcenter .jumboChild {
	z-index: 20;
	left: 0;
	right: 0;
	display: block;
}

.pageCarousel h1 {
	font-size: 37px;
	line-height: 53px;
}

.pageCarousel h2 {
	line-height: initial;
}

.pageCarousel div#header h2.slideNumber{
	font-size: 6em;
	padding: 0;
}

/* copy positioning - pad it off of number. */
.pageCarousel .carousel-inner h2.jumboChild {
	position: relative;
}

@media (max-width: 767px){
	.pageCarousel div#header h2.slideNumber{
		font-size: 5em;
	}
}

@media (max-width: 479px){
	.pageCarousel div#header h2.slideNumber{
		font-size: 3em;
	}
}

@media (max-width: 319px){
	.pageCarousel div#header h2.slideNumber{
		font-size: 2.5em;
		line-height: 35px;
	}
}


/* override ol:last-child margin, 
which breaks in-page infographic carousel */

/*.pageCarousel ol.carousel-indicators {
	margin: 0 0 0 0 !important;
}

.pageCarousel ol.carousel-indicators li:last-child {
  	margin: 0 0 0 3px !important;
  	float: right;
}*/


/* Carousel timing in choa.js */

/*Info Graphic carousel with class "pageCarousel" _______________________________*/

/*Set bottom spacing*/
.pageCarousel {
	margin: 20px 0;
}

/* vertical centering of content */
.pageCarousel .container.jumboContainer {
	display: table;
	height: 100%;
	display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

@media(max-width: 767px){
	.pageCarousel .jumboContainer {
		height: 100%;
	}
}

.pageCarousel .jumboChild {
	display: table-cell;
	vertical-align: middle;
}

/* carousel title */
.pageCarousel .jumbotron .container h1 {
	text-align: center;
}

.pageCarousel .jumbotron .container {
	width: 45%;
    height: 100%;
    margin: 0 auto;
	z-index: 9;
}

@media(max-width: 1399px){
	.pageCarousel .jumbotron .container {
		width: 86% !important;
	}
}

@media(max-width: 1199px){
	.pageCarousel .jumbotron {
	}
}

@media(max-width: 991px){
	.pageCarousel .jumbotron .container {
		width: 75% !important;
	}
}

@media(max-width: 767px){
	.pageCarousel .jumbotron .container {
	}
}

@media(max-width: 479px){
	.pageCarousel .jumbotron .container {
		width: 100% !important;
	}
	.pageCarousel .jumbotron {
	}
}

.pageCarousel .jumbotron h1 {
	color: #fff;
	margin: 0;
	z-index: 9;
	text-align: center;
}

.pageCarousel .jumbotron p {
	font-family: Arial, Helvetica, sans-serif;
	color: #fff;
	position: relative;
	z-index: 9;
}

/* Hero headers for various devices */
@media(max-width: 767px){
	.pageCarousel .jumbotron h1 {
		font-size: 28px;
		line-height: 39px;
	}
}

@media(max-width: 400px){
	.pageCarousel .jumbotron h1 {
		font-size: 18px;
		line-height: 36px;
	}
}

.pageCarousel .jumbotron {
	margin: 0 0px 11px;
	padding: 0;
    margin-bottom: inherit;
}

.pageCarousel .jumbotron.intro {
	margin-top: 0;
}

.pageCarousel .jumbotron .navbar {
	font-size: 11px;
	line-height: 1.6;
}

/*Overwite bootstrap*/
.pageCarousel .container .jumbotron, .pageCarousel .container-fluid .jumbotron {
	border-radius: 0;
}

/*Mobile Nav Title*/
.pageCarousel #pageNavTitleMobile {
	display: none;
}

/* Jumbotron Hero icon. */
.pageCarousel .image-icon {
	margin: 0 auto;
	position: relative;
	z-index: 9;
}

.pageCarousel .page-content{
	text-align: center;
	padding: 10px;
}

.pageCarousel #header {
    background-repeat: no-repeat;
    /*object-fit: cover;*/
}

.pageCarousel .carousel-inner {
	height: auto;
}

/*END Info Graphic carousel with class "pageCarousel" ____________________________ */


.pageCarousel.infoGraphic .jumbotron {
	margin: 0 0px 11px;
	padding: 0;
    margin-bottom: inherit;
    min-height: 355px
}

@media (max-width: 991px) {
	.pageCarousel.infoGraphic .jumbotron {
		min-height: 430px;
	}
}

@media (max-width: 767px) {
	.pageCarousel.infoGraphic .jumbotron {
		min-height: 405px;
	}
}

@media (max-width: 600px) {
	.pageCarousel.infoGraphic .jumbotron {
		min-height: 325px;
	}
}

@media (max-width: 479px) {
	.pageCarousel.infoGraphic .jumbotron {
		min-height: 245px;
	}
}

@media (max-width: 419px) {
	.pageCarousel.infoGraphic .jumbotron {
		min-height: 210px;
	}
}

@media (max-width: 319px) {
	.pageCarousel.infoGraphic .jumbotron {
		min-height: 200px;
	}
}

@media (min-width: 1200px) {
	.pageCarousel.infoGraphic .jumbotron {
		min-height: 430px;
	}
}

.jumbotron {
	background-color: transparent;
}

.otherGalleryImages {
	display: none;
 }

.pageCarousel .carousel-inner .item {
	-webkit-background-size: cover;
	     -o-background-size: cover;
	        background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	
}

.pageCarousel .carousel-inner .item:nth-child(1),
.pageCarousel .carousel-inner .item:nth-child(5) {
	background-color: #00a94f; /*green*/
}

.pageCarousel .carousel-inner .item:nth-child(2),
.pageCarousel .carousel-inner .item:nth-child(6) {
	background-color: #0081c6; /*blue*/
}

.pageCarousel .carousel-inner .item:nth-child(3),
.pageCarousel .carousel-inner .item:nth-child(7) {
	background-color: #bd2f92; /*magenta*/
}

.pageCarousel .carousel-inner .item:nth-child(4),
.pageCarousel .carousel-inner .item:nth-child(8) {
	background-color: #fcb31c; /*yellow*/
}


/*  Second Color Cadence option w/ first image in carousel*/

.pageCarousel.option-2 .carousel-inner .item:nth-child(2),
.pageCarousel.option-2 .carousel-inner .item:nth-child(6) {
	background-color: #00a94f; /*green*/
	
}

.pageCarousel.option-2 .carousel-inner .item:nth-child(3),
.pageCarousel.option-2 .carousel-inner .item:nth-child(7) {
	background-color: #0081c6; /*blue*/
	
}

.pageCarousel.option-2 .carousel-inner .item:nth-child(4),
.pageCarousel.option-2 .carousel-inner .item:nth-child(8) {
	background-color: #bd2f92; /*magenta*/
	
}

.pageCarousel.option-2 .carousel-inner .item:nth-child(5),
.pageCarousel.option-2 .carousel-inner .item:nth-child(9) {
	background-color: #fcb31c; /*yellow */
}