/* _________________ Original CSS _________________ */


/*!
 * Lightbox for Bootstrap 3 by @ashleydw
 * https://github.com/ashleydw/lightbox
 *
 * License: https://github.com/ashleydw/lightbox/blob/master/LICENSE
 */

.ekko-lightbox-container {
	position: relative;
}

.ekko-lightbox-nav-overlay {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100%;
	height: 100%;
}

.ekko-lightbox-nav-overlay a {
	z-index: 100;
	display: block;
	width: 49%;
	height: 100%;
	font-size: 30px;
	color: #fff;
	text-shadow: 2px 2px 4px #000;
	opacity: 0;
	-webkit-filter: dropshadow(color=#000000, offx=2, offy=2);
	        filter: dropshadow(color=#000000, offx=2, offy=2);
	-webkit-transition: opacity 0.5s;
					transition: opacity 0.5s;
}

.ekko-lightbox-nav-overlay a:empty {
	width: 49%;
}

.ekko-lightbox a:hover {
	text-decoration: none;
	opacity: 1;
	color: #FFF;
}

.ekko-lightbox .glyphicon-chevron-left {
	left: 0;
	float: left;
	padding-left: 15px;
	text-align: left;
}

.ekko-lightbox .glyphicon-chevron-right {
	right: 0;
	float: right;
	padding-right: 15px;
	text-align: right;
}

.ekko-lightbox .modal-footer {
	text-align: left;
}


/* _________________ Digitas-added syles _________________ */

/* This is for ekko-lihtbox only - shouldn't affect Bootstrap modals. It could: Remove "ekko-lightbox" classes. */

.ekko-lightbox .modal-header{
	border-bottom: 1px solid #b1b1b1;
}

/* Style Bootstrap "X" button. */
.ekko-lightbox .modal-header button.close {
	text-shadow: none;
	border: solid 2px black;
	width: 30px;
	height: 30px;
	font-size: 24px;
	line-height: 24px;
	border-radius: 15px;
}

.ekko-lightbox h4.modal-title {
	font-family: "Nunito", Arial, Helvetica, sans-serif;
	color: #5d5d5e;
}

.ekko-lightbox .modal-content {
	border-radius: 0;
	margin-top: 20%;
}

.ekko-lightbox .modal-footer{
	border-top: 1px solid #b1b1b1;
	background-color: #f1f4f8;
	/*padding: 15px 100px 15px 15px;*/	/*was*/
	padding: 15px 15px 30px;
}

.ekko-lightbox .modal-content span.imageCounter {
    float: right;
    margin: -36px 15px 0 0;

    margin-top: -40px;
    width: 100px;
    padding-top: 10px;
    text-align: right;
    font-weight: bold;
}

.ekko-lightbox-nav-overlay a:empty {
    width: 15%;
	/* IE doesn't like transparent or empty divs acting as links. */			
	background: url("../images/transparent.png") repeat 0 0;
}

/* glyphs don't show up properly on mobile. This is realy a hack.*/
@media (max-width: 767px){
	/*comment OUT these next two, if mobile still doesn't work.*/
	a.glyphicon-chevron-left {
		position: relative;
		top: 46%;
		height: 25%;
	}
	a.glyphicon-chevron-right {
		position: relative;
		top: 46%;
		height: 25%;
	}
	/*comment IN these next two, if mobile still doesn't work.*/
	a.glyphicon-chevron-left:before {
		/*content: "";*/
	}
	a.glyphicon-chevron-right:before {
		/*content: "";*/
	}

	.ekko-lightbox-nav-overlay a {
		opacity: .4;
		display: block;
		line-height: 1 !important;
	}
}
