/* _________________________________ Special styles for Site Core HTML text Editor _________________________________ */
 
/*** Fix these elsewhere, THEN fix these computed-classstyles here - as needed. ***/
 
p.paragraph-headline {
       font-family: Arial, Helvetica, sans-serif;
       color: #646464;
       max-width: 844px;
       margin: 32px auto;
       font-size: 24px;
       line-height: 36px;
       text-align: center;
       padding: 10px;
}
 
       @media(max-width: 767px){
              p.paragraph-headline {
                     font-size: 20px;
                     line-height: 32px;
                     margin: 18px auto 6px;
              }
       }
 
       @media(max-width: 400px){
              p.paragraph-headline {
                     font-size: 18px;
                     line-height: 28px;
                     margin: 18px auto 6px;
              }
       }
 
p.paragraph-content-headline {
       color: #666666;
       font-size: 22px;
       font-family: "Nunito", Arial, Helvetica, sans-serif;
}
p.paragraph-content-subheadline {
    font-family: "Nunito", Arial, Helvetica, sans-serif;
    font-size: 16px;
}
p.paragraph-pull-quote {
   color: #666666;
   font-family: Arial, Helvetica, sans-serif;
   font-size: 21px;
   line-height: 26px;
}
 
ul.list-bulleted-col {
       color: #666666;
       font-family: Arial, Helvetica, sans-serif;
       font-size: 16px;
       list-style-type: disc;
       margin: 0 4% 10px 0;
       display: inline-block;
       vertical-align: top;
       width: 41%;
}
 
a.link-normal {
       color: #008ac6;
       display: block !important;
       font-family: Arial, Helvetica, sans-serif !important;
}
 
a.link-normal:hover {
       color: #008ac6;
       text-decoration: underline;
}
 
a.page-link {
       color: #008ac6;
       font-family: Nunito, Arial, Helvetica, sans-serif;
       display: inline-block;
       font-size: 16px;
       font-stretch: normal;
       font-style: normal;
       font-variant: normal;
       font-weight: normal;
       line-height: 16px;
       text-align: left;
       text-decoration: none;
       text-rendering: auto;
       margin: 10px 0;
       cursor: pointer;
       margin: 10px 0;
}
 
a.page-link:hover {
       text-decoration: underline;
}
 
a.page-link::after {
       font-family: FontAwesome;
       content: "\f138";
       margin-left: 5px;
       text-decoration: none;
       display: inline-block;
}
 
a.page-link::after:hover {
       text-decoration: none;
}
 
a.page-link.spanish::after {
       display: none;
}
 
a.contact-info-email {
    color: #666666;
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 13px !important;
    font-weight: bold !important;
    display: inline-block;
}
a.contact-info-email:hover {
       color: #008ac6 !important;
}
 
a.contact-info-phone {
    color: #666666;
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 13px !important;
    font-weight: bold !important;
    display: inline-block;
}
a.contact-info-phone:hover {
       color: #008ac6 !important;
}
 
/*For English/Spanish links. Put this class on last a tag.*/
a.english-spanish-link {
       font-family: "Nunito", Arial, Helvetica, sans-serif!important;
       font-size: 16px!important;
       line-height: 1;
       color: #008ac6;
       margin-top: 5px !important;
}
 
a.link-arrow {
       color: #008ac6;
       margin-top: 5px !important;
}
 
a.link-arrow::after {
       content: "\f138";
       font-family: "FontAwesome";
       font-size: 16px;
       line-height: 16px;
       text-decoration: none !important;
       display: inline-block;
}
 
a.link-arrow:hover {
       text-decoration: none !important;
}
 
 
/*Can't give CMS 2 global classes for editor! Breaks other areas - see Home page. */
 
h3 {
       color: #00a94f;
       font-family: Nunito, Arial, Helvetica, sans-serif;
       font-size: 24px;
       font-weight: 500;
       line-height: 26.3999996185303px;
}
 
h4 {
       color: #666666;
       font-family: Nunito, Arial, Helvetica, sans-serif;
       font-size: 22px;
       font-weight: 500;
       line-height: 27px;
}
 
/* ____________________________________________________________________________________ */